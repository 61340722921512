// Idiomas para datalayers de GTM
export const arrayLangKeys: any = {
  es: 'spa',
  en: 'eng',
	de: 'deu',
	fr: 'fra',
	it: 'ita'
}

// Orden ciudades
export const ordenCiudades = [
  { name: 'Bogotá', destination_id: 456683, orden: 1 },
  { name: 'Madrid', destination_id: 28988, orden: 2 },
  { name: 'Barcelona', destination_id: 9292, orden: 3 },
  { name: 'Lima', destination_id: 1097817, orden: 4 },
  { name: 'Mexico', destination_id: 799154, orden: 5 },
  { name: 'Panamá', destination_id: 935331, orden: 6 }
]

// LATAM country ids
export const latamCountries = [
  'AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'CU', 'DO', 'EC', 'SV', 'GT', 'HN', 
  'MX', 'NI', 'PA', 'PY', 'PE', 'PR', 'UY', 'VE'
]